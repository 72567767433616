import React from "react";
import styled, { keyframes } from "styled-components";

const Wrapper = styled.div `
  transform: scale(.8);
  margin: ${props => props.margin || "-20px 0px 20px 40px"};
`;
const keyframesLoader = keyframes `
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
`;

const LoadingDiv = styled.div `
  color: black;
  font-size: 10px;
  margin: 0px auto;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  animation-delay: -0.16s;
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  animation-fill-mode: both;
  animation: ${keyframesLoader} 1.8s infinite ease-in-out;

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 0;
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    animation-fill-mode: both;
    animation: ${keyframesLoader} 1.8s infinite ease-in-out;
  }
  &:before {
    left: -3.5em;
    animation-delay: -0.32s;
  }
  &:after {
    left: 3.5em;
    animation-delay: 0.32s;
  }
`;

const Loading = props => (
  <Wrapper {...props}>
    <LoadingDiv />
  </Wrapper>
);

export default Loading;