import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import PostcardList from "./PostcardList";

import { TextField } from '@material-ui/core';

const Flex = styled.div`
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
`;

function Scheduler({ postcardList, setPostcardList }) {
  const [cadence, setCadence] = useState(7);
  const today = new Date();

  const [selectedDate, setSelectedDate] = useState(today);

  const dateFns = new DateFnsUtils();

  useEffect(() => {
    if (!selectedDate) return;

    const newList = [...postcardList].map((p,i) => {
      p.date = dateFns.addDays(selectedDate, (i * cadence))
      return p;
    })
    setPostcardList(newList);

  }, [postcardList.length, cadence, selectedDate])

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <>
        { postcardList.length > 1 && (
          <Flex>
            <div style={{ marginRight: "20px" }}>Ship postcards every</div>
            <TextField
              value={cadence}
              onChange={e => setCadence(e.target.value)}
              type="number"
              inputProps={{ min: "1", step: "1" }}
              margin="normal"
              style={{ width: "40px", marginRight: "20px" }}
            />
            <div style={{ marginRight: "20px" }}>days, starting on</div>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format={"MM/dd/yyyy"}
              margin="normal"
              value={selectedDate}
              onChange={setSelectedDate}
              minDate={today}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </Flex>
        )}
        { postcardList.length === 1 && (
          <Flex>
            <div style={{ marginRight: "20px" }}>Order postcard on</div>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format={"MM/dd/yyyy"}
              margin="normal"
              value={selectedDate}
              onChange={setSelectedDate}
              minDate={today}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </Flex>
        )}

        <PostcardList
          postcardList={postcardList}
          setPostcardList={setPostcardList}
        />
      </>
    </MuiPickersUtilsProvider>
  );
}
export default Scheduler;