import React from 'react';
import styled from 'styled-components';
import { Link } from "react-router-dom";

import { Button } from '@material-ui/core';

const Flex = styled.div`
  display: flex;
  align-items: center;
`;
const Wrapper = styled.div`
  margin: 100px auto 50px;
  padding: 0 20px;
  @media (max-width: 1160px) {
    margin: 40px auto;
  }
`;
const Tagline = styled.div`
  font-size: 1.25em;
  @media (max-width: 690px) {
    font-size: 1em;
  }
`;
const UL = styled.ul`
  padding-inline-start: 20px;
  margin-bottom: 40px;
  @media (max-width: 690px) {
    font-size: .85em;
  }
`;

const Landing = () => {
  return (
    <Flex>
      <Wrapper>
        <Tagline>i would like to send you a postcard.</Tagline>

        <UL>
          <li>let's move beyond social media</li>
          <li>let's have a physical connection</li>
          <li>$5/month, i mail you a postcard once a week</li>
          <li>each postcard is a photo i took and a short writeup</li>
          <li>cancel anytime, you won't hurt my feelings</li>
        </UL>

        <Link to="/checkout" style={{ textDecoration: "none" }}>
          <Button variant="contained" color="primary">yes i want a postcard</Button>
        </Link>
        <br />
        <Link to="/about" style={{ textDecoration: "none", marginTop: "20px", display: "inline-block" }}>
          <Button variant="outlined" color="primary">wtf?</Button>
        </Link>
      </Wrapper>
    </Flex>
  );
};

export default Landing;