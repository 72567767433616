import React from 'react';
import {
  CardElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';

import Button from '@material-ui/core/Button';

const CreditCard = ({ email, createSubscription }) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();
    stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details: {
        email
      }
    }).then(function(result) {
      if (result.error)
        console.log(result.error);
      else
        createSubscription(result.paymentMethod.id);    
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement
        options={{
          style: {
            base: {
              fontSize: '18px',
              color: '#333333',
              '::placeholder': {
                color: '#aab7c4',
              },
            },
            invalid: {
              color: '#9e2146',
            },
          },
        }}
      />
      <Button type="submit" variant="contained" color="primary" disabled={!stripe}>
        Pay
      </Button>
    </form>
  );
};

export default CreditCard;