import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import store_config from "../assets/store_config";

const initialState = {
  isAdmin: false,
  config: store_config
}

const reducers = function calls(state = { ...initialState }, action) {
  switch (action.type) {
    case "SET_ADMIN":
      return { ...state, isAdmin: action.isAdmin };
    case "SIGNED_IN":
      return { ...state, signedIn: action.signedIn };
    default:
      return state;
  }
};

export default history =>
  combineReducers({
    router: connectRouter(history),
    reducers
  });