import React, { useState } from 'react';

import ShippingForm from "../ui/ShippingForm";
import Loading from '../ui/Loading';
import { Button } from '@material-ui/core';

function Shipping({ changePane }) {
  const blankAddress = {
    name: "",
    address_line1: "",
    address_line2: null,
    address_city: "",
    address_state: "",
    address_zip: ""
  }
  const [address, setAddress] = useState(blankAddress);
  const [loading, setLoading] = useState(false);

  const submitAddress = () => {
    setLoading(true);
    changePane(address);
  }

  return (
    <div>
      <ShippingForm
        address={address}
        setAddress={setAddress}
      />
      { loading ? <Loading margin="-10px auto 40px" /> : (
        <Button variant="contained" color="primary"
          style={{ marginTop: "40px" }}
          disabled={!address.address_zip.length}
          onClick={submitAddress}
        >
          Continue
        </Button>
      )}
    </div>
  );
}
export default Shipping;