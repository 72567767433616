import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import { withRouter, Link } from "react-router-dom";

import PageWrapper from '../ui/PageWrapper';
import Loading from '../ui/Loading';
import ProfileAddress from "./ProfileAddress";
import ProfilePlan from "./ProfilePlan";
import SignOut from "./SignOut";

import { Paper, Button } from '@material-ui/core';

const B = styled.b`
  display: inline-block;
`;
const Note = styled.div`
  font-size: 14px;
  margin: 0px 0 40px;
  text-align: center;
`;

function Profile({ history }) {
  const [profile, setProfile] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`/get-profile`)
      .then(res => res.json())
      .then(result => {
        if (result.error) {
          history.push("/sign-in");
        } else {
          setProfile(result);
          setLoading(false);
        }
      }).catch(err => history.push("/sign-in"))
  }, []);

  return (
    <div>
      <PageWrapper small>
        <Paper style={{ padding: "40px 40px 60px", position: "relative" }}>
          { loading ? <Loading margin="0" /> : (
            <>
              <h3 style={{ marginTop: 0 }}>hello {profile.email}</h3>

              {!profile.start_date && (
                <Link to="/checkout" style={{ textDecoration: "none" }}>
                  <Button variant="contained" color="primary">yes i want a postcard</Button>
                </Link>
              )}

              {!profile.canceled_at && profile.start_date && (
                <div>you have been subscribed since <B>{profile.start_date}</B></div>
              )}

              { profile.address && (
                <ProfileAddress profile={profile} setProfile={setProfile} />
              )}

              { (profile.canceled_at || profile.card) && (
                <ProfilePlan profile={profile} setProfile={setProfile} />
              )}

              <SignOut />
            </>
          )}
        </Paper>
      </PageWrapper>

      <Note>btw, all of the payment stuff is handled securely through stripe</Note>
    </div>
  );
};
export default withRouter(Profile);