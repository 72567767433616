import React from 'react';
import styled from 'styled-components';

import { Tabs, Tab } from "@material-ui/core";

const Table = styled.table `
  padding: 20px;
  width: 100%;
  > tbody > tr > td {
    padding: 10px 5px;
  }
`;

const OrderTable = (props) => {
  const { view_status, orders } = props;

  return (
    <div>
      <Tabs value={view_status} onChange={(e, v) => props.switchView(v)}>
        <Tab value="active" label="active" />
        <Tab value="canceled" label="canceled" />
        <Tab value="past_due" label="past_due" />
      </Tabs>
      <Table>
        <tbody>
          { orders.map((order,i) => {
            const time = new Date(order.createdAt);
            return (
              <tr key={`order${i}`}>
                <td>{order.email}</td>
                <td>
                  <div>{`${time.getFullYear()}-${time.getMonth() + 1}-${time.getDate()}`}</div>
                </td>
                <td>{order.paidUntil}</td>
                <td style={{ fontSize: "14px" }}>
                  <div>{order.stripeSubscriptionId}</div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};
export default OrderTable;