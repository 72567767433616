import React from 'react';
import styled from 'styled-components';

import Divider from '@material-ui/core/Divider';

const Row = styled.div `
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  font-size: 16px;
  .full {
    flex: 1;
    margin-left: 10px;
  }
  > span {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .black-text { color: #000; }
  .small-text { font-size: 14px; }
`;

function CartSmall({ item }) {
  const total = item && item.unit_amount/100;

  return (
    <div>
      <Row>
        Postcard Subscription
      </Row>
      <Row>
        <i>you will be billed monthly</i>
      </Row>
      <Row>
        <i>you can cancel at any time</i>
      </Row>
      <Divider style={{ margin: "20px 0" }}/>
        <Row>
          <span>Total</span>
          <span className="black-text">
            {total && total.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
          </span>
        </Row>
    </div>
  );
}
export default CartSmall;