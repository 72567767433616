import React, { useState } from 'react';
import { withRouter } from "react-router-dom";

import { Paper } from '@material-ui/core';

import CheckoutHeader from './CheckoutHeader';
import Email from './Email';
import Shipping from './Shipping';
import CreditCards from './CreditCards';

function CheckoutFlow({ classes, priceID, history }) {

  const [pane, setPane] = useState(0);

  const [email, setEmail] = useState("");
  const [address, setAddress] = useState({});
  const [subscriberId, setSubscriberId] = useState();
  
  const [stripeCustomerID, setStripeCustomerID] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const createCustomer = () => {
    // create customer entity in stripe
    fetch("/create-customer", {
        method: 'POST',
        headers: new Headers({ 'content-type': 'application/json' }),
        body: JSON.stringify({ email })
      }).then((response) => response.json())
      .then((json) => {
        setStripeCustomerID(json.id);
        setPane(1);
      })
  }

  const createAccount = (addr) => {
    // create customer acct in our db
    fetch("/create-account", {
        method: 'POST',
        headers: new Headers({ 'content-type': 'application/json' }),
        body: JSON.stringify({
          email,
          address: addr
        })
      }).then((response) => response.json())
      .then((json) => {
        setSubscriberId(json.subscriberId)
        setPane(2);
      })
  }

  const createSubscription = paymentID => {
    if (!stripeCustomerID) {
      setError("Sorry, an error has occurred. Please refresh the page and try again.")
      return;
    }
    setLoading(true);

    fetch("/create-subscription", {
        method: 'POST',
        headers: new Headers({ 'content-type': 'application/json' }),
        body: JSON.stringify({
          subscriberId,
          customerId: stripeCustomerID,
          paymentMethodId: paymentID,
          priceId: priceID
        })
      }).then(response => response.json())
      .then(subscription => {
        if (subscription.error)
          setError(subscription.error.message);
        else {
          setTimeout(() => {
            history.push({ pathname: '/confirm', state: { subscription } });
          }, 50);
        }
      })
  }

  let displayAddress;
  if (address.address_zip) {
    displayAddress = (
      <div className={classes.inputInfo}>
          <div>{address.name}</div>
          <div>{address.address_line1}</div>
          <div>{address.address_line2}</div>
          <div>{address.address_city}, {address.address_state}</div>
          <div>{address.address_zip}</div>
        </div>
    );
  }

  return (
    <>
      <Paper className={classes.paper}>
        <CheckoutHeader text={"Create Your Account"} classes={classes.heading}
          pane={0} currentPane={pane}
          changePane={() => setPane(0)}
        />
        { pane === 0 ? (
          <Email
            email={email} 
            setEmail={setEmail}
            changePane={() => createCustomer()}
          />
        ) : (
          <div className={classes.inputInfo}>
            {email}
          </div>
        )}
      </Paper>
      <Paper className={classes.paper}>
        <CheckoutHeader text={"Mailing Address"} classes={classes.heading}
          pane={1} currentPane={pane}
          changePane={() => setPane(1)}
        />
        { pane === 1 ? (
          <Shipping
            changePane={(addr) => {
              setAddress(addr)
              createAccount(addr)
            }}
          />
        ) : (
          displayAddress
        )}
      </Paper>
      <Paper className={classes.paper}>
        <CheckoutHeader text={"Payment"} classes={classes.heading}
          pane={2} currentPane={pane}
          changePane={() => setPane(1)}
        />
        { !!error && <p style={{ color: "#f40" }}>{error}</p> }
        { !error && pane === 2 && !loading && (
          <CreditCards
            email={email}
            createSubscription={createSubscription}
          />
        )}
        { !error && pane === 2 && loading && (
          <div style={{ marginTop: "20px" }}>
            one sec, we are setting up your account! please remain on this page
          </div>
        )}
      </Paper>
    </>
  );
}
export default withRouter(CheckoutFlow);