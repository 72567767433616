import React from 'react';
import styled from "styled-components";
import { Link } from 'react-router-dom';

import { useSelector } from "react-redux";
import usePageTracking from "../usePageTracking";

const AdminLinks = styled.div`
  position: absolute;
`;
const H3 = styled.h3`
  font-size: 3em;
  margin: 0;
  color: black;
  text-align: center;
  @media (max-width: 690px) {
    margin-top: 20px;
  }
`;
const Big = styled.span`
  font-size: 6em;
  line-height: .5em;
  letter-spacing: -10px;
  padding-right: 20px;

  @media (max-width: 1160px) {
    font-size: 5em;
    display: block;
    padding-right: 0;
  }
  @media (max-width: 690px) {
    font-size: 3em;
    letter-spacing: -3px;
  }
`;
const Small = styled.span`
  vertical-align: top;
  line-height: 2em;
  display: inline-block;
  @media (max-width: 1160px) {
    display: block;
    margin-top: 1.5em;
  }
  @media (max-width: 690px) {
    margin-top: 1em;
    line-height: 1em;
  }
`;

function Banner({ classes, quantity, width }) {
  const isAdmin = useSelector(state => state.reducers.isAdmin);

  usePageTracking();
  let links = [];

  if (isAdmin) {
    links.push({ url: "/upload", label: "Upload" })
    links.push({ url: "/postcards", label: "Postcards" })
    links.push({ url: "/subscribers", label: "Subscribers" })
  }

  const renderLinks = (link, i) => (
    <Link to={link.url} key={`l${i}`} style={{ display: "block", color: "black" }}>
      {link.label}
    </Link>
  )

  return (
    <div style={{ padding: "20px" }}>
      <AdminLinks>{ links.map(renderLinks) }</AdminLinks>
      <Link to="/" style={{ textDecoration: "none" }}>
        <H3>
          <Big>yes</Big>
          <Small> i want a postcard</Small>
        </H3>
      </Link>
    </div>
  );
};
export default Banner;