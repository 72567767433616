import React, { useEffect } from 'react';
// import { StripeProvider } from 'react-stripe-elements';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import Landing from './components/Landing';
import AboutPage from "./components/AboutPage";
import Upload from "./components/Upload";

import ScrollToTop from './components/ui/ScrollToTop';
import Banner from './components/ui/Banner';
import LoginStatus from './components/ui/LoginStatus';

import Checkout from './components/checkout/Checkout';
import Confirm from './components/checkout/Confirm';

import SignIn from './components/subscriber/SignIn';
import ResetPassword from "./components/subscriber/ResetPassword";
import Profile from './components/subscriber/Profile';

import Orders from './components/admin/Orders';
import Postcards from './components/admin/Postcards';
import AdminLogin from './components/admin/AdminLogin';

import NotFound404 from "./components/NotFound404";

import { useSelector, useDispatch } from "react-redux";

function App(props) {
  // const [quantity, setQuantity] = useState();

  const config = useSelector(state => state.reducers.config);
  const isAdmin = useSelector(state => state.reducers.isAdmin);
  const dispatch = useDispatch();

  useEffect(() => {
    fetch('/user/')
      .then(res => res.json())
      .then(result => {
        dispatch({ type: "SET_ADMIN", isAdmin: result.isAdmin })
        dispatch({ type: "SIGNED_IN", signedIn: result.signedIn })
      }).catch(err => {
        dispatch({ type: "SIGNED_IN", signedIn: false })
      })
  }, []);

  if (!config || typeof isAdmin === "undefined") return (null);

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: config.colors.primary.main,
        dark: config.colors.primary.dark,
        contrastText: config.colors.primary.contrastText
      },
      secondary: {
        main: config.colors.secondary.main,
        contrastText: config.colors.secondary.contrastText
      },
    },
    typography: {
      fontFamily: [
        'Quicksand',
        'Roboto',
        'Helvetica',
        'sans-serif'
      ]
    },
    overrides: {
      MuiRadio: {
      colorSecondary: {
        '&$checked': {
          color: '#333333',
        },
      },
    },
    }
  });

  const stripePromise = loadStripe(config.api_key);

  return (
    <Elements stripe={stripePromise}>
      <Router>
        <ScrollToTop>
          <MuiThemeProvider theme={theme}>
            <LoginStatus />
            <Banner />
            <Switch>
              <Route exact path="/" component={Landing} />
              <Route exact path="/about" component={AboutPage} />
              <Route exact path="/upload" component={Upload} />

              <Route exact path="/checkout"
                render={(props) => <Checkout config={config} item={config.products[0]} />}
              />
              <Route exact path="/confirm" component={Confirm} />

              <Route exact path="/sign-in" component={SignIn} />
              <Route exact path="/reset-password" component={ResetPassword} />
              <Route exact path="/profile" component={Profile} />
              
              <Route exact path="/admin" component={AdminLogin} />
              {isAdmin && (
                <Route exact path="/subscribers" component={Orders} />
              )}
              {isAdmin && (
                <Route exact path="/postcards" component={Postcards} />
              )}
            
              <Route component={NotFound404} />
            </Switch>
          </MuiThemeProvider>
        </ScrollToTop>
      </Router>
    </Elements>
  );
};
export default App;