import React, { useState } from 'react';
import styled from "styled-components";

import PageWrapper from './ui/PageWrapper';
import PostcardForm from "./postcard/PostcardForm";
import Scheduler from "./postcard/Scheduler";

import { Paper, Button } from '@material-ui/core';

const H2 = styled.h2`
  margin-top: 0;
  @media (max-width: 650px) {
    font-size: 18px;
    line-height: 22px;
    font-weight: 700;
  }
`;

function Upload() {
  const [postcardList, setPostcardList] = useState([]);
  const [saved, setSaved] = useState(false);

  const callPostcardAPI = (postcard) => {
    const postBody = {
      postcardId: postcard.postcardId,
      printDate: postcard.date,
    }
    return new Promise(function(resolve, reject) {
      fetch("/schedule-postcard", {
          method: 'POST',
          headers: new Headers({ 'content-type': 'application/json' }),
          body: JSON.stringify(postBody)
        }).then((response) => response.json())
        .then(result => {
          resolve(result)
        });
    });
  }

  const prepareOrder = () => {
    const createRows = postcardList.map(callPostcardAPI);
    Promise.all(createRows).then(() => setSaved(true));
  }

  const addPostcard = postcard => {
    const newList = [...postcardList];
    newList.push(postcard);
    setPostcardList(newList);
  }

  return (
    <PageWrapper>
      <Paper style={{ padding: "20px", marginBottom: "40px" }}>
        <H2 style={{ marginTop: 0 }}>Create a Postcard Design</H2>
        <PostcardForm addPostcard={addPostcard} />
      </Paper>

      <Paper style={{ padding: "20px", marginBottom: "40px" }}>
        <H2 style={{ margin: 0 }}>Postcard Schedule</H2>
        <Scheduler
          postcardList={postcardList}
          setPostcardList={setPostcardList}
        />

        { !saved ? (
          <Button variant="contained" color="primary" onClick={prepareOrder}>
            Save Postcards
          </Button>
        ) : (
          <h3>saved! :)</h3>
        )}
      </Paper>
    </PageWrapper>
  );
};
export default Upload;