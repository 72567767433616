import React, { useState } from 'react';
import { withRouter } from "react-router-dom";

import { useDispatch } from "react-redux";

import PageWrapper from '../ui/PageWrapper';
import { Paper, TextField, Button } from '@material-ui/core';

function EmailForm({ email, setEmail, sendPasscodeEmail }) {
  return (
    <>
      <div style={{ fontSize: ".85em" }}>
        sign in to your account with a one-time passcode, which will be sent to your email
      </div>
      <TextField
        style={{ marginTop: "20px" }}
        autoComplete="email"
        label="Email Address"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        onKeyDown={e => {
          if (e.keyCode === 13) sendPasscodeEmail()
        }}
      />
      <Button
        style={{ marginTop: "30px" }}
        variant="contained" color="primary" 
        onClick={sendPasscodeEmail}
      >
        email sign in code
      </Button>
    </>
  );
};

function PasswordForm({ email, signUserIn }) {
  const [password, setPassword] = useState("");

  return (
    <>
      <div style={{ fontSize: ".85em" }}>an email has been sent to <b>{email}</b></div>
      <p style={{ fontSize: ".85em" }}>please enter the six-digit passcode below</p>
      <TextField
        style={{ marginTop: "20px" }}
        label="Passcode"
        value={password}
        onChange={e => setPassword(e.target.value)}
        onKeyDown={e => {
          if (e.keyCode === 13) signUserIn(password)
        }}
      />
      <Button
        style={{ marginTop: "30px" }}
        variant="contained" color="primary" 
        onClick={() => signUserIn(password)}
      >
        sign in
      </Button>
    </>
  );
};

function SignIn(props) {
  const [email, setEmail] = useState("");
  const [error, setError] = useState();
  const [sentEmail, setSentEmail] = useState(false);

  const dispatch = useDispatch();

  const sendPasscodeEmail = () => {
    setError(null);
    setSentEmail(true);
    fetch('/register', {
        method: 'POST',
        headers: new Headers({
          'content-type': 'application/json',
          'Access-Control-Allow-Credentials': true }
        ),
        withCredentials: true,
        credentials: 'include',
        body: JSON.stringify({ email })
      }).then(res => res.json())
      .then(json => {
        console.log("emailed passcode");
      }).catch(err => console.log(err));
  }

  const signUserIn = (password) => {
    setError(null);
    fetch('/sign-in', {
        method: 'POST',
        headers: new Headers({ 'content-type': 'application/json' }),
        credentials: 'include',
        withCredentials: true,
        body: JSON.stringify({ password })
      })
      .then(res => res.json())
      .then(json => {
        if (json.error) {
          setError(json.error)
          return;
        } else {
          dispatch({ type: "SIGNED_IN", signedIn: true })
          setTimeout(() => {
            props.history.push('/profile');
          }, 50);
        }
      }).catch(err => console.log(err));
  }

  

  return (
    <PageWrapper small>
      <Paper style={{ padding: "40px", display: "flex", flexDirection: "column" }}>
        { !sentEmail ? (
          <EmailForm
            email={email}
            setEmail={setEmail}
            sendPasscodeEmail={sendPasscodeEmail}
          />
        ) : (
          <PasswordForm email={email} signUserIn={signUserIn} />
        )}
        { error && <div style={{ marginTop: "20px" }}>{error}</div>}
      </Paper>
    </PageWrapper>
  );
};
export default withRouter(SignIn);