import React, { useState } from 'react';
import styled from "styled-components";

import Loading from '../ui/Loading';
import { Button, Dialog, DialogContent, DialogActions } from '@material-ui/core';

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const B = styled.b`
  display: inline-block;
`;

function ProfilePlan({ profile, setProfile }) {
  const [cancelModal, showCancelModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const cancelSubscription = () => {
    setLoading(true);
    fetch(`/delete-subscription`)
      .then(res => res.json())
      .then(result => {
        const newProfile = Object.assign(profile, result)
        setProfile(newProfile);
        setLoading(false);
        showCancelModal(false);
      });
  }

  return (
    <div>
      <h3>subscription status</h3>
      { profile.canceled_at ? (
        <>
          <Flex>
            <div>subscription start</div>
            <B>{profile.start_date}</B>
          </Flex>
          <Flex>
            <div>subscription end</div>
            <B>{profile.end_date}</B>
          </Flex>
          <Flex>
            <div>canceled on</div>
            <B>{profile.canceled_at}</B>
          </Flex>
        </>
      ) : (
        <>
          <Flex>
            <div>card on file</div>
            <B>{`${profile.card.brand} • ${profile.card.last4}` }</B>
          </Flex>
          <Flex>
            <div>next charge</div>
            <B>{profile.next_charge}</B>
          </Flex>
          <Button
            variant="outlined" size="small" 
            onClick={() => showCancelModal(true)}
            style={{ marginTop: "10px" }}
          >
            cancel subscription
          </Button>
        </>
      )}

      <Dialog
        open={cancelModal}
        onBackdropClick={() => showCancelModal(false)}
      >
        <DialogContent>
          are you sure? this action is permanent and cannot be undone
        </DialogContent>
        <DialogActions>
          { loading ? <Loading margin="-10px auto 40px" /> : (
            <Button
              variant="outlined" size="small" 
              onClick={cancelSubscription}
              style={{ marginTop: "10px" }}
            >
              I'm sure, cancel my subscription
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default ProfilePlan;