import React, { useState } from 'react';
import styled from "styled-components";

import ShippingForm from "../ui/ShippingForm";
import { Button, Dialog, DialogContent, DialogActions } from '@material-ui/core';

const Small = styled.div`
  font-size: .85em;
`;

function ProfileAddress({ profile, setProfile }) {
  const blankAddress = {
    name: "",
    address_line1: "",
    address_line2: null,
    address_city: "",
    address_state: "",
    address_zip: ""
  }
  const [address, setAddress] = useState(blankAddress);
  const [addressModal, showAddressModal] = useState(false);

  const updateAddress = () => {
    fetch('/update-address', {
        method: 'POST',
        headers: new Headers({ 'content-type': 'application/json' }),
        credentials: 'same-origin',
        body: JSON.stringify({ address })
      })
      .then(res => res.json())
      .then(json => {
        if (json.success) {
          const newProfile = Object.assign(profile, { address });
          setProfile(newProfile);
          showAddressModal(false);
        }
      });
  }

  return (
    <div>
      <h3>mailing address</h3>
      <Small>{profile.address.name}</Small>
      <Small>{profile.address.address_line1}</Small>
      <Small>{profile.address.address_line2}</Small>
      <Small>{`${profile.address.address_city}, ${profile.address.address_state}`}</Small>
      <Small>{profile.address.address_zip}</Small>

      <Button 
        variant="outlined" size="small"
        style={{ marginTop: "10px" }}
        onClick={() => {
          setAddress(blankAddress);
          showAddressModal(true)
        }}
      >
        change address
      </Button>

      <Dialog
        open={addressModal}
        onBackdropClick={() => showAddressModal(false)}
      >
        <DialogContent>
          <ShippingForm address={address} setAddress={setAddress} />
        </DialogContent>
        <DialogActions style={{ padding: "0 20px 20px 0"}}>
          <Button
            variant="outlined" size="small" 
            onClick={() => showAddressModal(false)}
            style={{ marginRight: "20px" }}
          >
            cancel
          </Button>
          <Button
            variant="contained" size="small" color="primary"
            onClick={updateAddress}
          >
            update address
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default ProfileAddress;