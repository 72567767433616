import React, { useState } from 'react';

import PageWrapper from '../ui/PageWrapper';
import { Paper, TextField, Button } from '@material-ui/core';

function ResetPassword(props) {
  const [email, setEmail] = useState("");

  const resestPassword = () => {
    /* do magic reset */
  }

  return (
    <PageWrapper small>
      <Paper style={{ padding: "40px", display: "flex", flexDirection: "column" }}>
        <TextField
          autoComplete="email"
          label="Email Address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button
          style={{ marginTop: "20px" }}
          variant="contained" color="primary"
          disabled={!email.length}
          onClick={resestPassword}
        >
          reset password
        </Button>
        <div style={{ marginTop: "40px" }}>you will receive an email with instructions for how to set a new password.</div>
      </Paper>
    </PageWrapper>
  );
};
export default ResetPassword;