import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div `
  max-width: ${props => props.small ? "400px" : "1100px"};
  margin: 84px auto 50px;
  @media (max-width: 1160px) {
    margin-top: 40px;
  }
`;

const PageWrapper = props => (
  <Wrapper small={props.small}>
    { props.children}
  </Wrapper>
);
export default PageWrapper;