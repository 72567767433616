import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import { getThumbnail } from "../../data/util";

import PageWrapper from '../ui/PageWrapper';
import { Paper } from "@material-ui/core";

const Table = styled.table `
  padding: 20px;
  width: 100%;
  > tbody > tr > td {
    padding: 10px 5px;
  }
`;
const Image = styled.div `
  background-image: url(${props => props.img});
  width: 125px;
  height: 125px;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  @media (max-width: 650px) {
    width: 62px;
    height: 62px;
  }
`;

function Postcards() {
  const [postcards, setPostcards] = useState([]);
  const [imageData, setImageData] = useState([]);

  useEffect(() => {
    fetch('/list-postcards')
      .then(res => res.json())
      .then(results => {
        setPostcards(results)
      });
  }, []);

  useEffect(() => {
    const photos = [...postcards].map(i => i.postcardId);

    Promise.all(photos.map(getThumbnail)).then(results => {
      setImageData(results)
    })
  }, [postcards]);

  return (
    <PageWrapper>
      <Paper style={{ position: "relative "}}>
        <Table>
          <tbody>
            {postcards.map((p,i) => (
              <tr key={`post${i}`}>
                <td>{p.printDate}</td>
                <td><Image img={imageData[i]} /></td>
                <td>{p.postcardBack.postcard_text}</td>
                <td>{p.status}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Paper>
    </PageWrapper>
  );
};
export default Postcards;