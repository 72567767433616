import React from 'react';
import { Link, withRouter } from "react-router-dom";

import PageWrapper from '../ui/PageWrapper';
import { Paper } from '@material-ui/core';

const Confirm = ({ location }) => {
  if (!location || !location.state) return null;
  const { subscription } = location.state;
  if (!subscription) return null;

  return (
    <PageWrapper small>
      <Paper style={{ padding: "40px 40px 60px" }}>
        <h2 style={{ marginTop: 0, fontWeight: 600 }}>Thank you!</h2>
        <p>A confirmation email has been sent to <b>{subscription.latest_invoice.customer_email}</b>.</p>

        <div style={{ marginTop: "20px"}}>
          You can view or modify your subscription on your <Link to="/profile" style={{ color: "black", fontWeight: "bold" }}>profile</Link>.
        </div>
      </Paper>
    </PageWrapper>
  );
}
export default withRouter(Confirm);