import React from 'react';
import styled from "styled-components";
import { withRouter } from "react-router-dom";

import { useDispatch } from "react-redux";

const Wrapper = styled.div `
  position: absolute;
  bottom: 0;
  right: 0;
  clip-path: polygon(15% 0,100% 0,100% 100%,0 100%);
  background-color: #CCCAC3;
  padding: 7px 14px 7px 24px;
  cursor: pointer;
  color: black;
  &:hover {
    background-color: #B3B0AB;
  }
`;

function SignOut({ history }) {
  const dispatch = useDispatch();
  
  const signOut = () => {
    fetch(`/sign-out`)
      .then(res => res.json())
      .then(result => {
        if (result.signedOut) {
          dispatch({ type: "SIGNED_IN", signedIn: false })
          history.push("/");
        }
      })
  };

  return (
    <Wrapper onClick={signOut}>
      sign out
    </Wrapper>
  );
};
export default withRouter(SignOut);