import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const Email = ({ email, setEmail, changePane }) => {
  return (
    <div>
      <TextField
        autoComplete="email"
        label="Email Address"
        value={email}
        onChange={(e) => setEmail(e.target.value.trim())}
        margin="normal"
        fullWidth
      />
      <Button variant="contained" color="primary"
        style={{ marginTop: "20px" }}
        disabled={!email.length}
        onClick={changePane}
      >
        Continue
      </Button>
    </div>
  );
}
export default Email;