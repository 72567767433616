import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from "react-router-dom";

import { useSelector } from "react-redux";

const Wrapper = styled.div `
  position: absolute;
  top: 0;
  right: 0;
  clip-path: polygon(0 0,100% 0,100% 100%,15% 100%);
  background-color: #CCCAC3;
  padding: 7px 14px 7px 24px;
  cursor: pointer;
  color: black;
  &:hover {
    background-color: #B3B0AB;
  }
  @media (max-width: 690px) {
    font-size: .85em;
    padding: 7px 12px 7px 20px;
  }
`;

function LoginStatus() {
  const signedIn = useSelector(state => state.reducers.signedIn);

  const linkOpions = [
    { route: "/sign-in", text: "sign in" },
    { route: "/profile", text: "profile" }
  ]
  const [link, setLink] = useState(linkOpions[0]);

  useEffect(() => {
    // to do: improve
    if (signedIn) setLink(linkOpions[1]);
    else setLink(linkOpions[0]);
  }, [signedIn])

  return (
    <Link to={link.route}>
      <Wrapper>
        {link.text}
      </Wrapper>
    </Link>
  );
}
export default LoginStatus;