import React from 'react';

import { TextField } from '@material-ui/core';

function ShippingForm({ address, setAddress }) {
  const handleChange = (name, value) => {
    const newAddress = Object.assign({ ...address }, {
      [name]: value
    });
    setAddress(newAddress);
  }

  return (
    <div>
      <TextField
        autoComplete="name"
        label="Name"
        value={address.name}
        onChange={(e) => handleChange('name', e.target.value.slice(0,40))}
        fullWidth
        margin="normal"
      />

      <TextField
        autoComplete="shipping address-line1"
        label="Street Address"
        value={address.address_line1}
        onChange={(e) => handleChange('address_line1', e.target.value.slice(0,64))}
        fullWidth
        margin="normal"
      />
      <TextField
        autoComplete="shipping address-line2"
        label="Apt, suite, etc (optional)"
        value={address.address_line2 || ""}
        onChange={(e) => handleChange('address_line2', e.target.value.slice(0,64))}
        fullWidth
        margin="normal"
      />
      <TextField
        autoComplete="shipping address-level2"
        label="City"
        value={address.address_city}
        onChange={(e) => handleChange('address_city', e.target.value)}
        style={{ marginRight: "40px" }}
        margin="normal"
      />
      <TextField
        autoComplete="shipping address-level1"
        label="State"
        value={address.address_state}
        onChange={(e) => handleChange('address_state', e.target.value)}
        margin="normal"
      />
      <div>
        <TextField
          autoComplete="shipping postal-code"
          label="Zip"
          value={address.address_zip}
          onChange={(e) => handleChange('address_zip', e.target.value)}
          margin="normal"
        />
      </div>
    </div>
  );
}
export default ShippingForm;