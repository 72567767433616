function arrayBufferToBase64(buffer) {
  var binary = '';
  var bytes = [].slice.call(new Uint8Array(buffer));

  bytes.forEach((b) => binary += String.fromCharCode(b));

  return window.btoa(binary);
};

export function getThumbnail(img) {
  return new Promise((resolve, reject) => {
    fetch(`/get-postcard/${img}/thumbnail.png`)
      .then(res => res.arrayBuffer())
      .then(buffer => {
        var base64Flag = 'data:image/jpeg;base64,';
        var imageStr = arrayBufferToBase64(buffer);
        resolve(base64Flag + imageStr);
      })
  })
}