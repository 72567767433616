import React from 'react';
import styled from 'styled-components';
import { Link } from "react-router-dom";

import { Button } from '@material-ui/core';

const Wrapper = styled.div`
  max-width: 640px;
  margin: 100px auto 40px;
  padding: 0 20px;
`;
const A = styled.a`
  text-decoration: none;
  font-weight: bold;
  color: inherit;
`;

const AboutPage = () => (
  <Wrapper>
    <div>hello, this is a project by <A href="http://rachelbinx.com/">rachel binx</A></div>

    <p style={{ marginTop: "40px"}}>
      i'd like to share photos from my life, untethered from the apps.
    </p>
    <p>these postcards will be physical and in-person — a small stand-in for the conversations i wish i could be having with you.</p>
    <p>the postcards you receive will be the only documentation of this project, and the experiences i am having. i won't be posting anything online.</p>
    <p>what will it feel like? will you and i deepen our connection?</p>

    <Link to="/checkout" style={{ textDecoration: "none", marginTop: "40px", display: "block" }}>
      <Button variant="contained" color="primary">yes i want a postcard</Button>
    </Link>
  </Wrapper>
);

export default AboutPage;